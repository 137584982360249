.detail_event_block {
  &_back_arrow{
    font-size: 18px;
    line-height: 34px;
    font-weight: bold;
    color: #5C8DE8;
    cursor: pointer;
    padding-bottom: 12px;
    display: inline-block;

    .arrow {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }
  width: 100%;
  .header_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &_title_block {
      display: flex;
      flex-direction: row;
      align-items: center;
      .title {
        font-size: 32px;
        line-height: 48px;
        padding-right: 24px;
      }
      .label {
        width: 83px;
        height: 25px;
      }
      .burger {
        display: flex;
        padding-left: 16px;
        align-content: center;
        position: relative;
        cursor: pointer;
        &_menu_container {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          top: 28px;
          right: 0;
          width: 201px;
          height: 112px;
          background-color: #FFFFFF;
          border: 1px solid #AFBCD4;
          box-sizing: border-box;
          box-shadow: 1px 1px 8px rgba(0, 7, 43, 0.08);
          border-radius: 3px;
          padding-left: 10px;
          z-index: 1;
          &_item {
            display: flex;
            align-content: center;
            font-size: 16px;
            color: #626262;
            padding: 15px;
            cursor: pointer;
            &_icon {
              padding-right: 15px;
            }
          }
        }
      }
    }
    .custom-button-component {
      display: flex;
      flex-direction: row;
      height: 45px;
      font-size: 16px;
      line-height: 100%;
      min-width: 0;
      min-height: 0;

      .add_volunteer {
        padding: 14px 22px;
        margin-right: 24px;
      }
      .export {
        padding: 14px 16px;
      }
    }
  }
  .event-vol {
    overflow: auto;
    &_times_roles {
      display: flex;
      flex-direction: row;
    }
    &_row-style {
      display: flex;
      align-items: flex-start;
      padding-top: 16px;
      padding-bottom: 15px;
      cursor: pointer;
    }
    &_row-style:hover {
      background: #f7fbfd;
    }
    &_row-active {
      display: flex;
      align-items: flex-start;
      padding-top: 16px;
      padding-bottom: 15px;
      cursor: pointer;
      background: #f7fbfd;
    }
    &_role-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
      margin-right: 4px;
    }
    &_checkbox {
      margin-top: 5px;
    }
  }

}
