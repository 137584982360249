@mixin max-width-1024 {
  @media screen and (max-width: 1024px) {
    @content
  }
}

@mixin max-width-880 {
  @media screen and (max-width: 880px) {
    @content
  }
}

@mixin max-width-768 {
  @media screen and (max-width: 768px) {
    @content
  }
}

@mixin max-width-480 {
  @media screen and (max-width: 480px) {
    @content
  }
}

