/************Main Colors************/
$Main--Primary: #5c8de8;
$Main--Secondary: #ff9900;
$Main--SecondaryLight: #ffcd00;
$Main--Third: #e6e9ec;
$Main--ThirdLight: #eef6fc;
$Main--Success: #5ce872;
$Main--Error: '';
$Main--Warning: #ee875b;
$Main--WarningDark: #f0774c;
$Main--Default: #ffffff;
/************End Main Colors************/

/************Text Colors************/
$Text--Primary: #212121;
$Text--PrimaryLight: #3a3d3d;
$Text--Secondary: #386fd4;
$Text--SecondaryLight: #4176d9;
$Text--SecondaryLighten: #5c8de8;
$Text--Third: #394d69;
$Text--ThirdLight: #7387ac;
$Text--Error: #ff0000;
$Text--ErrorLight: #ff6f3e;
$Text--Default: #000000;
$Text--DefaultLight: #090909;
$Text--White: #ffffff;
$Text--Gray: #a5b0c0;
$Text--GrayDark: #707070;
/************End Text Colors************/

/************Box Shadow Colors************/
$BoxShadow--Primary: 0px 4px 15px rgba(0, 0, 0, 0.25); // Login popup
$BoxShadow--Secondary: 0px 4px 7px rgba(100, 124, 142, 0.18); // Cards
/************END Box Shadow Colors************/

/************Background Colors************/
$Background--Default: #ffffff;
$Background--Primary: #f8f8f8;
$Background--Gray: #EFEFEF;
$Background--Blue: #EFF4FD;
$Background--Yellow: #FEF0B2;
$Background--LightGreen: #DFEFAA;
$Background--Green: #BFDF57;
$Background--DarkGreen: #9BB548;
/************Background Colors************/

/************Borders************/
$Border--Blue: #5C8DE8FA;
$Border--Gray: #CCCCCCFA;
$Border--Red: #F44336;
/************Borders************/

:export {
  /************Main Colors************/
  mainPrimary: $Main--Primary;
  mainSecondary: $Main--Secondary;
  mainSecondaryLight: $Main--SecondaryLight;
  mainThird: $Main--Third;
  mainThirdLight: $Main--ThirdLight;
  mainSuccess: $Main--Success;
  mainError: $Main--Error;
  mainWarning: $Main--Warning;
  mainWarningDark: $Main--WarningDark;
  mainDefault: $Main--Default;
  /************End Main Colors************/

  /************Text Colors************/
  textPrimary: $Text--Primary;
  textPrimaryLight: $Text--PrimaryLight;
  textSecondary: $Text--Secondary;
  textSecondaryLight: $Text--SecondaryLight;
  textSecondaryLighten: $Text--SecondaryLighten;
  textThird: $Text--Third;
  textThirdLight: $Text--ThirdLight;
  textError: $Text--Error;
  textErrorLight: $Text--ErrorLight;
  textDefault: $Text--Default;
  textDefaultLight: $Text--DefaultLight;
  textWhite: $Text--White;
  textGray: $Text--Gray;
  textGrayDark: $Text--GrayDark;
  /************End Text Colors************/

  /************Box Shadow Colors************/
  boxShadowPrimary: $BoxShadow--Primary;
  boxShadowSecondary: $BoxShadow--Secondary;
  /************End Box Shadow Colors************/

  /************Background Colors************/
  backgroundDefault: $Background--Default;
  backgroundPrimary: $Background--Primary;
  backgroundGray: $Background--Gray;
  backgroundBlue: $Background--Blue;
  backgroundYellow: $Background--Yellow;
  backgroundGreen: $Background--Green;
  backgroundDarkGreen: $Background--DarkGreen;
  backgroundLightGreen: $Background--LightGreen;
  /************Background Colors************/

  /************Borders************/
  borderBlue: $Border--Blue;
  borderGray: $Border--Gray;
  borderRed: $Border--Red;
  /************Borders************/
}
