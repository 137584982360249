@import "../mixins";
.feedback-form-block-question-list-item {
  margin-top: 32px;
  @include max-width-768 {
    margin-top: 20px;
  }
  @include max-width-768 {
    margin-top: 10px;
  }
  &__question {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    @include max-width-480 {
      font-size: 16px;
    }
  }
  &__value {
    margin-top: 10px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #212121;
    .radio_button_wrapper {
      margin-top: 10px;
    }
    .checkbox_item {
      margin-top: 5px;
      label {
        width: 100%;
        padding-left: 20px;
        margin-left: -20px;
      }
    }
  }
  .feedback-error-message {
    display: block;
    margin-top: 2px;
    font-size: 12px;
    line-height: 14px;
    color: #FF6F3E;
    font-weight: 400;
  }
}
