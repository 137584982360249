.mentorship_popup_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(23, 66, 147, 0.8);
  overflow: auto;
  z-index: 99;
}

.mentorship_popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  border-radius: 4px;
  background: white;
  .mentorship_close-icon {
    position: absolute;
    right: -55px;
    top: -23px;
    width: 36px;
    height: 36px;
    background: white;
    box-shadow: 0 4px 7px rgba(100, 124, 142, 0.09);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 25px;
  }
  &_content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
