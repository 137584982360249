@import "./civic-champs-shared/core/styles/colors.scss";

html {
  overflow-y: hidden;
}

.fullheight {
  height: 100% !important;
}

.fullwidth {
  width: 100% !important;
}

html * {
  >::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  >::-webkit-scrollbar-thumb {
    background-color: #74777F;
    border-radius: 4px;
  }
  scrollbar-color:#74777F transparent;
  scrollbar-width: thin;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $Main--Default !important;
}

.menu-logo {
  padding: 16px 0 0 16px
}

.menu-sidebar {
  > div {
    scrollbar-width: none;
    overflow: scroll;
    -ms-overflow-style: none;
  }
  > div::-webkit-scrollbar {
    display: none;
  }

  hr {
    background: $Text--Secondary;
  }

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;

    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {

      span {
        color: $Main--Primary;
      }

      background-color: $Main--Default;
    }

    .menu-item {
      background: transparent;
      border-bottom: 1px solid #386FD5;
      margin: 0;
      height: 60px;
      max-height: 60px;
      padding-left: 30px;

      &.bottom {
        margin-top: auto;
        border-top: 1px solid #386FD5;
        background: #437DE9;
      }

      &:last-child {
        border-bottom: 0;
        background: #437DE9;
      }

      > div span {
        font-family: Open Sans Regular, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 14px;
      }
    }
  }
}


#root {
  height: 100vh;
}

.nested {
  padding-left: 10px !important;
}

.margin-b-30 {
  margin-bottom: 30px;
}

.MuiPickersToolbar-toolbar {
  background-color: $Main--Primary !important;
  color: $Main--Default !important;
}

.MuiPickersToolbar-toolbar h1 {
  color: $Main--Default !important;
}

.MuiPickersToolbar-toolbar h2 {
  color: $Main--Default !important;
}

.MuiPickersToolbar-toolbar h3 {
  color: $Main--Default !important;
}

.MuiPickersToolbar-toolbar h4 {
  color: $Main--Default !important;
}

.MuiPickersToolbar-toolbar h5 {
  color: $Main--Default !important;
}

.MuiPickersToolbar-toolbar h6 {
  color: $Main--Default !important;
}

.MuiTabs-root {
  background-color: $Main--Primary !important;
}


.MuiTabs-root .MuiSvgIcon-root {
  color: $Main--Default !important;
}

.tab-button-black {
  .MuiSvgIcon-root {
    color: $Text--Default !important;
  }
}


#edit_activity .MuiButton-label {
  color: $Main--Primary !important;
}

.MuiPickersDay-daySelected {
  background-color: $Main--Primary !important;
  color: $Main--Default !important;
}

.MuiPickersClockPointer-pointer {
  background-color: $Main--Primary !important;

}
.MuiPickersClockNumber-clockNumberSelected {
  background-color: $Main--Primary !important;
  color: $Main--Default !important;

}

.ck-editor__editable {
  min-height: 165px;
}

.pac-container {
  z-index: 1500 !important;
}

.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncated-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncated-multiline-3 {
  @extend .truncated-multiline;
  -webkit-line-clamp: 3;
}

.hidden {
  display: none !important;
}
