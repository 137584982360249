.create-event-module {
    display: flex;
    flex-direction: column;
    height: 100%;
    .create-event-module-steps {
      margin-bottom: 40px;
    }
  
  .create-event-module-content {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      position: relative;
      &__main {
        width: 100%;
        max-width: 760px;
        &.full-width {
          max-width: 100%;
        }
      }
      &__sidebar {
        z-index: -1;
        padding: 21px 29px;
        width: 100%;
        max-width: 395px;
        //min-height: calc(100vh - 140px);
        //height: 100%;
        height: 100px;
        //margin-right: -90px;
        border-radius: 4px 0 0 0;
        box-shadow: 0 4px 7px rgba(100, 124, 142, 0.18);
        border-top: 1px solid #ABB6CC;
        border-left: 1px solid #ABB6CC;
        border-bottom: 1px solid #ABB6CC;
        // position: absolute;
        // right: 0;
      }
    }
  }