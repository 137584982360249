/* NOTE: the order of these styles DO matter */

/*
 * Style for all selected days between a range of dates
 */
.CalendarDay__selected_span {
    background: #C7DAEE;
    color: #90979F;
    border: 1px solid #DCDCDC;
}

/*
 * Style for a selected date or the endpoints of a range of dates
 */
.CalendarDay__selected {
    background: #809ADB;
    color: white;
    border: 1px solid #DCDCDC;
}

/*
 * Style when a day is hovered over
 */
.CalendarDay__selected:hover,
.CalendarDay__selected_span:hover {
    background: #FFCD00;
    color: white;
    border: 1px solid #DCDCDC;
}

.DateRangePicker_picker {
    box-shadow: 8px 8px 15px 5px #888888;
    left: -400px !important;
}