.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border: 0 !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-radius: 4px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable.ck-focused , .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners.ck-focused {
  border-color: #000000;
}
