/* NOTE: the order of these styles DO matter */

/*
 * Style for all selected days between a range of dates
 */
.CalendarDay__selected_span {
    background: #C7DAEE;
    color: #90979F;
    border: 1px solid #DCDCDC;
}

/*
 * Style for a selected date or the endpoints of a range of dates
 */
.CalendarDay__selected {
    background: #809ADB;
    color: white;
    border: 1px solid #DCDCDC;
}

/*
 * Style when a day is hovered over
 */
.CalendarDay__selected:hover,
.CalendarDay__selected_span:hover {
    background: #0F5DB5;
    color: white;
    border: 1px solid #DCDCDC;
}

.DateRangePicker_picker {
    box-shadow: 8px 8px 15px 5px #888888;
}

.DateRangePicker_picker__directionLeft {
    left: -400px !important;
}

.DateRangePicker_picker__directionRight {
    left: 0 !important;
}

div.DateRangePickerInput {
    border: 1px solid #EEF3F6;
    border-radius: 100px;
    padding: 4px 16px;
}

div.DateInput, input.DateInput_input {
    border-radius: 100px;
    padding: 0;
}
div.DateRangePickerInput_arrow, input.DateInput_input {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #747778;
    border-bottom: 2px solid transparent;
}
div.DateRangePickerInput_arrow {
    margin: 0 4px;
}
div.DateInput {
    width: 80px;
}

button.DateRangePickerInput_calendarIcon {
    padding: 0;
    margin: 0 4px 0 8px;
}

.DateInput_input.DateInput_input__focused {
    border-bottom: 2px solid transparent;
    border-radius: 100px;
}
