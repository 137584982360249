.ck.ck-balloon-panel {
  z-index: 1400 !important /* Put a higher value that your MUI Dialog (generally 1300) */
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.transformed {
  transform: scale(0.7) translateX(-60px);
}

.ck.ck-editor__editable_inline.ck-content {
  padding: 0 12px;
}